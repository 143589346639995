<template>
  <v-container fluid>
    <v-row>
      <v-col cols="auto" md="12" sm="12">
        <v-card>
          <v-card-text v-if="currentSubscription">
            <v-row>
              <v-col cols="12" sm="6">
                <span class="d-block font-weight-bold">{{ $t('transactionsPage.currentSubscription') }}</span>
                <span class="d-block"> {{ getTranslatedPackage(currentSubscription?.subscription_type_id) }}</span>
              </v-col>
              <v-col cols="12" sm="6">
                <span class="d-block font-weight-bold">{{ $t('transactionsPage.nextBillingDate') }}</span>
                <span class="d-block"> {{ currentSubscription?.next_billing_date }}</span>
              </v-col>
              <v-col cols="12" sm="6">
                <span class="d-block font-weight-bold">{{ $t('transactionsPage.numberOfSeatsTaken') }}</span>
                <span class="d-block">{{ currentSubscription?.takes_seats }}</span>
              </v-col>
              <v-col cols="12" sm="6">
                <span class="d-block font-weight-bold">{{ $t('transactionsPage.numberOfSeatsAvailable') }}</span>
                <span class="d-block">{{ currentSubscription?.available_seats }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-data-table
          :footer-props="{
            pageText: `{0}-{1} ${$t('tables.of')} {2}`,
            itemsPerPageText: $t('tables.transactionsByPage'),
            itemsPerPageOptions: [5, 10, 20, 50, 100],
          }"
          :headers="transactionsHeaders"
          :no-data-text="$t('tables.noDataText')"
          :no-results-text="$t('tables.noDataText')"
          :items="transactions"
          :search="searchTransactionString"
          :class="!readOnly ? 'cursor-pointer elevation-1' : 'elevation-1'"
          @click:row="clickRow"
          multi-sort>
          <template v-slot:item.status="{ item }">
            {{ getTranslatedType(item.status) }}
          </template>

          <template v-slot:top>
            <v-toolbar flat>
              <template>
                <v-toolbar-title>
                  {{ $t('tables.transactions') }}
                </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>
              </template>

              <v-text-field
                v-model="searchTransactionString"
                append-icon="mdi-magnify"
                hide-details
                :label="$t('tables.search')"
                single-line></v-text-field>

              <v-spacer />

              <router-link
                to="/packages"
                class="black--text text-decoration-none"
                v-if="user.role_id === 2 || user.role_id === 4">
                <v-btn class="mb-2" color="primary" dark>
                  {{ $t('buttons.changePackage') }}
                </v-btn>
              </router-link>

              <v-dialog v-model="transactionDialog" max-width="700px">
                <template v-slot:activator="{ on, attrs }" v-if="!readOnly">
                  <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on">
                    {{ $t('buttons.add') }}
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="info title white--text font-weight-bold">
                    {{ $t(transactionFormTitle) }}
                    <v-spacer />
                    <v-icon class="mdi mdi-close" style="color: white" @click="closeTransactionEdit"></v-icon>
                  </v-card-title>

                  <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
                    <v-form lazy-validation @submit.prevent="handleSubmit(saveTransaction)">
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" lg="6" md="6" sm="12">
                              <validation-provider
                                rules="required|max:200"
                                v-slot="{ errors }"
                                name="invoice_number"
                                ref="invoiceNumberRef">
                                <v-text-field
                                  autofocus
                                  type="text"
                                  name="invoice_number"
                                  v-model="editedItem.invoice_number"
                                  clearable
                                  counter="200"
                                  :label="$t('transactionsPage.invoiceNumber')"
                                  :error-messages="errors"></v-text-field>
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" lg="6" md="6" sm="12">
                              <validation-provider rules="required" v-slot="{ errors }" name="status" ref="statusRef">
                                <v-autocomplete
                                  v-model="editedItem.status"
                                  :items="paymentStatuses"
                                  clearable
                                  item-text="name"
                                  item-value="id"
                                  :label="$t('transactionsPage.status')"
                                  :error-messages="errors"
                                  :no-data-text="$t('select.noDataAvailable')"
                                  @focus="$event.target.click()"></v-autocomplete>
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" lg="6" md="6" sm="12">
                              <validation-provider
                                rules="required|numeric_decimal|max:200"
                                v-slot="{ errors }"
                                name="total"
                                ref="totalRef">
                                <v-text-field
                                  type="text"
                                  name="total"
                                  v-model="editedItem.total"
                                  clearable
                                  counter="200"
                                  :label="$t('transactionsPage.total')"
                                  :error-messages="errors"></v-text-field>
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" lg="6" md="6" sm="12">
                              <validation-provider rules="required" v-slot="{ errors }" name="currency">
                                <v-autocomplete
                                  v-model="editedItem.currency"
                                  :items="currencies"
                                  clearable
                                  item-text="code"
                                  item-value="code"
                                  :label="$t('transactionsPage.currency')"
                                  :error-messages="errors"
                                  :no-data-text="$t('select.noDataAvailable')"
                                  @focus="$event.target.click()"></v-autocomplete>
                              </validation-provider>
                            </v-col>
                            <v-col lg="6" md="6" sm="12">
                              <v-menu
                                ref="billingAtDate"
                                v-model="billingAtDate"
                                :close-on-content-click="false"
                                :return-value.sync="editedItem.billed_at"
                                min-width="auto"
                                offset-y
                                transition="scale-transition">
                                <template v-slot:activator="{ on, attrs }">
                                  <validation-provider rules="required" v-slot="{ errors }" name="billed_at"
                                    ><v-text-field
                                      v-model="editedItem.billed_at"
                                      clearable
                                      :label="$t('transactionsPage.billedAt')"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      :error-messages="errors"></v-text-field>
                                  </validation-provider>
                                </template>
                                <DatePicker
                                  v-model="editedItem.billed_at"
                                  :model-config="modelConfig"
                                  mode="date"
                                  :first-day-of-week="firstDay"
                                  @input="$refs.billingAtDate.save(editedItem.billed_at)">
                                </DatePicker>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
                      </v-card-actions>
                    </v-form>
                  </validation-observer>
                </v-card>
              </v-dialog>

              <v-dialog v-model="transactionDialogDelete" max-width="650">
                <v-card>
                  <v-card-title class="info title white--text font-weight-bold">
                    {{ $t('transactionsPage.deleteTransaction') }}
                    <v-spacer />
                    <v-icon class="mdi mdi-close" style="color: white" @click="closeTransactionDelete"></v-icon>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <p>
                        {{ $t('transactionsPage.deleteMessage') }}
                      </p>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      :disabled="!enableTransactionSave"
                      class="primary"
                      text
                      @click="deleteTransactionItemConfirm">
                      {{ $t('buttons.yes') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:item.actions="{ item }" v-if="!readOnly">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-tooltip left>
                  <template v-slot:activator="{ on: tooltipOn }">
                    <v-btn text v-on="{ ...on, ...tooltipOn }">
                      <v-icon> mdi-dots-vertical </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('buttons.actions') }}
                  </span>
                </v-tooltip>
              </template>
              <v-list>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item @click="editTransactionItem(item)">
                      <v-icon class="mx-1" small v-bind="attrs">mdi-pencil</v-icon>
                      <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                        {{ $t('buttons.edit') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <span>{{ $t('tooltips.edit') }}</span>
                </v-tooltip>

                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item @click="deleteItem(item)">
                      <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                      <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                        {{ $t('buttons.delete') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <span>{{ $t('tooltips.delete') }}</span>
                </v-tooltip>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { transactionsHeaders } from '@/mixins/data-table-headers';
import { defaultTransaction } from '@/mixins/default-items';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import { DatePicker } from 'v-calendar/src/components';
import { formatDate } from '@/utils/formatDate';

export default {
  name: 'TransactionItem',
  props: ['organisationId'],
  components: { ButtonSubmit, DatePicker },
  data: () => ({
    user: {
      name: '',
      email: '',
      role_id: null,
    },
    searchTransactionString: '',
    transactionDialog: false,
    transactionDialogDelete: false,
    transactionFormTitle: 'transactionsPage.newItem',
    editedItem: {},
    editedItemDeleted: {},
    editedItemIndex: null,
    enableTransactionSave: true,
    loading: false,
    billingAtDate: null,
    modelConfig: {
      type: 'string',
      mask: 'DD-MM-YYYY',
    },
    readOnly: false,
    transactions: [],
    currencies: [{ code: 'EUR' }, { code: 'USD' }, { code: 'GPB' }],
    paymentStatuses: [],
    currentSubscription: null,
  }),
  created() {
    this.editedItem = Object.assign({}, defaultTransaction);
    this.loadUser();
    this.loadAllTransactions();
    this.loadAllPaymentStatuses();
    this.loadCurrentSubscription();
  },
  computed: {
    transactionsHeaders() {
      return this.readOnly
        ? transactionsHeaders(i18n).filter((header) => header.value != 'actions')
        : transactionsHeaders(i18n);
    },
  },
  methods: {
    loadUser() {
      this.user = this.$store.getters['auth/user'];
      if ([2, 4].includes(this.user.role_id)) {
        this.readOnly = true;
      }
    },
    async loadAllTransactions() {
      await this.$store.dispatch('transactions/getAllTransactions').then((res) => {
        this.transactions = res.data.map((item) => {
          return { ...item, billed_at: formatDate(item.billed_at) };
        });
      });
    },

    async loadCurrentSubscription() {
      await this.$store.dispatch('transactions/getCurrentSubscription').then((res) => {
        this.currentSubscription = { ...res.data, next_billing_date: formatDate(res.data.next_billing_date) };
      });
    },

    loadAllPaymentStatuses() {
      this.paymentStatuses = [
        { id: 'sent', name: i18n.t('transactionsPage.sent') },
        { id: 'completed', name: i18n.t('transactionsPage.completed') },
      ];
    },

    editTransactionItem(item) {
      this.editedItem = this.transactions.indexOf(item);
      this.editedItemIndex = this.transactions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.transactionFormTitle = 'transactionsPage.editItem';
      this.transactionDialog = true;
    },

    deleteItem(item) {
      this.editedItemDeleted = this.transactions.indexOf(item);
      this.editedItemDeleted = Object.assign({}, item);
      this.transactionDialogDelete = true;
    },

    async saveTransaction() {
      this.enableTransactionSave = false;
      this.loading = true;
      let path = 'saveTransaction';

      if (this.editedItem.id) {
        Object.assign(this.editedItem, { _method: 'POST' });
        path = 'updateTransaction';
      }

      this.editedItem.organisation_id = this.organisationId;

      await this.$store
        .dispatch('transactions/' + path, this.editedItem)
        .then(() => {
          this.loadAllTransactions();
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.closeTransactionEdit();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.enableTransactionSave = true;
          this.loading = false;
          this.transactionFormTitle = 'transactionsPage.newItem';
        });
    },

    async deleteTransactionItemConfirm() {
      await this.$store
        .dispatch('transactions/deleteTransaction', this.editedItemDeleted)
        .then((res) => {
          if (res.success) {
            this.loadAllTransactions();
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
            this.closeTransactionDelete();
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.closeTransactionDelete();
        });
    },

    closeTransactionEdit() {
      this.transactionDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultTransaction);
      });
      this.$refs.form.reset();
      this.transactionFormTitle = 'transactionsPage.newItem';
    },

    closeTransactionDelete() {
      this.transactionDialogDelete = false;
    },

    clickRow(item) {
      if (!this.readOnly) {
        this.editTransactionItem(item);
      }
    },

    getTranslatedType(type) {
      const statuses = {
        sent: 'sent',
        completed: 'completed',
      };

      return statuses[type] ? i18n.t(`transactionsPage.${statuses[type]}`) : '';
    },

    getTranslatedPackage(id) {
      const names = {
        9: 'Team 10',
        10: 'Team 25',
        11: 'Team 50',
      };

      return names[id] ? i18n.t(`packages.${names[id]}`) : '';
    },
  },

  watch: {
    transactionDialog(val) {
      val || this.closeTransactionEdit();
    },
    '$i18n.locale': {
      handler() {
        this.loadAllPaymentStatuses();
      },
    },
  },
};
</script>
